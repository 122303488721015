body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html{font-size:62.5%}body{font-family:'Montserrat',sans-serif;font-size:2rem;}body .App{display:flex;flex-direction:column;justify-content:center;align-items:center;margin-top:3rem}h1,h2,h3,h4{margin:0}.todo-list{background:rgba(255,0,0,0.5);padding:2rem 3rem;margin-bottom:2rem;}.todo-list h2{text-align:center}.todo-list form{display:flex;justify-content:center;padding:2rem;}.todo-list form input[type='text']{background:transparent;border:none;border-bottom:solid 1px rgba(255,255,255,0.6);caret-color:rgba(255,255,255,0.6);color:#fff;box-shadow:none;outline:none;padding:1rem;padding-bottom:0;}.todo-list form input[type='text']:focus{border-color:#fff}.todo-list form input[type='text']::-webkit-input-placeholder{color:rgba(255,255,255,0.6)}.todo-list form input[type='text']::-ms-input-placeholder{color:rgba(255,255,255,0.6)}.todo-list form input[type='text']::placeholder{color:rgba(255,255,255,0.6)}.todo-list .loader{margin-top:2rem}
